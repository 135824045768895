/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "context";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import MDTypography from "components/MDTypography";
import { Height, Padding } from "@mui/icons-material";
import PieChart from "examples/Charts/BarCharts/ReportsBarChart/PieChart";
import LineChart from "examples/Charts/BarCharts/ReportsBarChart/LineChart";
import StackedBarChart from "examples/Charts/BarCharts/ReportsBarChart/StackBarChart";
import { ApiContext } from "utils/ApiContext";
import HeatMap from "examples/Charts/BarCharts/ReportsBarChart/HeatMap";
import BoxPlot from "examples/Charts/BarCharts/ReportsBarChart/BoxPlot";
import ScatterPlotChart from "examples/Charts/BarCharts/ReportsBarChart/ScatterPlotChart";
import HistogramChart from "examples/Charts/BarCharts/ReportsBarChart/HistogramChart";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ApiProvider } from "utils/ApiContext";
import ScatterPlotMatrix from "examples/Charts/BarCharts/ReportsBarChart/ScatterPlotMatrixChart";
import ScatterPlotMatrixChart from "examples/Charts/BarCharts/ReportsBarChart/ScatterPlotMatrixChart";
import ScatterPlotMatrixLayout from "examples/Charts/BarCharts/ReportsBarChart/ScatterPlotMatrixLayout";
// import { ApiContext } from "utils/ApiContext";

const isTokenExpired = (token) => {
  if (!token) return true;
  try {
    const decoded = jwtDecode(token);
    return decoded.exp < Date.now() / 1000;
  } catch (error) {
    return true;
  }
};

function Dashboard({ FilterHospital, PatientDropdown }) {
  const { appointments, procedures } = reportsLineChartData;
  const {
    userData: { role, token },
    setUserData,
  } = useContext(AuthContext);

  const [selectedHospital, setSelectedHospital] = useState("");
  const [hospitals, setHospitals] = useState([]);

  const handleHospitalChange = (value) => {
    setSelectedHospital(value);
  };

  const {
    biologicTherapyCountByAge,
    biologicTherapyCountByGender,
    biologicTherapyProportion,
    biologicTherapySwitches,
    ostTestPatients,
    ostTestPatientsByVisit,
    ostTestByProgress,
    ostTestByProgressByage,
    averageNPS,
    ostTestPatientsByage,
    avgTreatmentNpsByVisit,
    avgNpsByGender,
    comorbiditiesByPerc,
    comorbiditiesByfreq,
    surgicalDataByYear,
    temporalTrendsNps,
    avgSnotByCategory,
    temporalTrendsSnot,
    surgicalProcedureByPerc,
    patientByMedication,
    medicationCorrelation,
    genderStatsPatients,
    ageDitributionPatient,
    testScoreByAge,
    // scatterMatrixData,
    loading,
    error,
  } = useContext(ApiContext);

  // useEffect(() => {
  //   console.log("Biologic Therapy Count By Age:", ageDitributionPatient);
  // }, [ageDitributionPatient]);

  // console.log(scatterMatrixData,'scatterMatrixData');
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (isTokenExpired(token)) {
        localStorage.removeItem("role");
        localStorage.removeItem("token");
        setUserData({ token: null, role: null });
        navigate("/authentication/sign-in");
      }
    };

    const intervalId = setInterval(checkTokenExpiration, 1000); // Check every second

    return () => clearInterval(intervalId);
  }, [token, navigate, setUserData]);

  const fetchHospitals = () => {
    fetch(`https://${process.env.REACT_APP_API_URL}/api/physician/recommend-hospital`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Custom-Header": "header value",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setHospitals(data.hospitals);
          setSelectedHospital(data.hospitals[0]);
        }
      })
      .catch((error) => {
        console.error("Error fetching hospitals:", error);
      });
  };

  useEffect(() => {
    fetchHospitals();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="medical_services"
                title="Total Hospitals"
                count={281}
                percentage={{
                  color: "success",
                  amount: "+12%",
                  label: "since last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="healing"
                title="Total Physicians"
                count="125"
                percentage={{
                  color: "success",
                  amount: "+5%",
                  label: "since last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="groups"
                title="Total Patients"
                count="9,302"
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "since last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="medical_information"
                title="Approved Consent Patients"
                count="57"
                percentage={{
                  color: "success",
                  amount: "+2%",
                  label: "since last month",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <div style={{ display: "flex", gap: "24px", justifyContent: "right" }}>
          {FilterHospital}
          {PatientDropdown}
        </div>
        {loading ? (
          <>
            {" "}
            <h1>Loading...</h1>{" "}
          </>
        ) : (
          <MDBox mt={4.5}>
            <MDBox mt={4.5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9} lg={6}>
                  <MDBox mb={3}>
                    <ReportsBarChart
                      color="info"
                      title="Biologics Patient Data"
                      description="Count of patients for each biologic therapy,
                segmented by gender."
                      date="Updated today"
                      chart={biologicTherapyCountByGender}
                      ylabel="No of Patients"
                      xlabel="Biologic Therapy"
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={9} lg={6}>
                  <MDBox mb={3}>
                    <ReportsBarChart
                      color="info"
                      title="Biologics Patient Data"
                      description="Count of patients for each biologic therapy, segmented by Age."
                      date="Updated today"
                      chart={biologicTherapyCountByAge}
                      ylabel="No of Patients"
                      xlabel="Biologic Therapy"
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={4.5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9} lg={6}>
                  <MDBox mb={3}>
                    <PieChart
                      color="info"
                      title="Proportion of Patients on Biologic Therapies"
                      description="Breakdown of biologics prescribed"
                      date="Last updated: Dec 2024"
                      chart={biologicTherapyProportion}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={9} lg={6}>
                  <MDBox mb={3}>
                    <LineChart
                      color="info"
                      title="Average NPS Scores Over Time"
                      description="Average NPS scores for biologics across years"
                      date="Last updated: Dec 2024"
                      chart={averageNPS}
                      ylabel="NPS Score"
                      xlabel="Years"
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={4.5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9} lg={6}>
                  <StackedBarChart
                    color="info"
                    title="Therapy Switches by Reason"
                    description="A stacked bar chart representing the number of therapy switches by reason."
                    date="2024"
                    chart={biologicTherapySwitches}
                    xlabel="Therapies"
                    ylabel="No Of Patients"
                  />
                </Grid>
                <Grid item xs={12} md={9} lg={6}>
                  <MDBox mb={3} sx={{ height: "100%" }}>
                    <ReportsBarChart
                      color="info"
                      title="Number of patients in each OST Test category"
                      description="Count of patients in each OST test,
                segmented by gender."
                      date="Updated today"
                      chart={ostTestPatients}
                      ylabel="No of Patients"
                      xlabel="OST Test Category"
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={7.5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9} lg={6}>
                  <StackedBarChart
                    color="info"
                    title="Changes in OST Test scores"
                    description="Changes in OST Test scores between visits"
                    date="2024"
                    chart={ostTestPatientsByVisit}
                    xlabel="Visit Dates"
                    ylabel="OST Test Scores"
                  />
                </Grid>
                <Grid item xs={12} md={9} lg={6}>
                  <MDBox mb={3} sx={{ height: "100%" }}>
                    <LineChart
                      color="info"
                      title="Progression of OST Test scores"
                      description="Progression of OST Test scores for individual patients by Gender"
                      date="Updated today"
                      chart={ostTestByProgress}
                      ylabel="Oss Test Score"
                      xlabel="Dates"
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={7.5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9} lg={6}>
                  <LineChart
                    color="info"
                    title="Progression of OST Test scores"
                    description="Progression of OST Test score By age"
                    date="2024"
                    chart={ostTestByProgressByage}
                    ylabel="OST Test Score"
                    xlabel="Dates"
                  />
                </Grid>
                <Grid item xs={12} md={9} lg={6}>
                  <MDBox mb={3} sx={{ height: "100%" }}>
                    <ReportsBarChart
                      color="info"
                      title="Number of patients in each OST Test category"
                      description="Count of patients in each OST test,
                segmented by age."
                      date="Updated today"
                      chart={ostTestPatientsByage}
                      ylabel="No of Patients"
                      xlabel="OST Test Category"
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>

            <MDBox mt={7.5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9} lg={6}>
                  <LineChart
                    color="info"
                    title="Average NPS for different treatments."
                    description="Average NPS for different treatments between different visits."
                    date="2024"
                    chart={avgTreatmentNpsByVisit}
                    ylabel="Avg NPS Score"
                    xlabel="Visit Dates"
                  />
                </Grid>
                <Grid item xs={12} md={9} lg={6}>
                  <MDBox mb={3} sx={{ height: "100%" }}>
                    <BoxPlot
                      color="info"
                      title="Distribution of NPS by gender or age."
                      description="Distribution of Average NPS by gender or age."
                      date="Updated today"
                      chart={avgNpsByGender}
                      xlabel="Gender"
                      ylabel="Avg NPS Score"
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={7.5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9} lg={6}>
                  <LineChart
                    color="info"
                    title="Temporal trends in NPS for patients."
                    description="Temporal trends in NPS for patients Over Visits."
                    date="Updated today"
                    chart={temporalTrendsNps}
                    ylabel="Avg NPS Score"
                    xlabel="Visit Dates"
                  />
                </Grid>
                <Grid item xs={12} md={9} lg={6}>
                  <MDBox mb={3} sx={{ height: "100%" }}>
                    <ReportsBarChart
                      color="info"
                      title="Frequency of comorbidities by gender and age"
                      description="Frequency of comorbidities segmented by gender and age"
                      date="Updated today"
                      chart={comorbiditiesByfreq}
                      ylabel="No of Patients"
                      xlabel="Comorbidities"
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={7.5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9} lg={6}>
                  <PieChart
                    color="info"
                    title="Percentage distribution of patients by comorbidities."
                    description="Percentage distribution of patients by comorbidities."
                    date="2024"
                    chart={comorbiditiesByPerc}
                  />
                </Grid>
                <Grid item xs={12} md={9} lg={6}>
                  <MDBox mb={3} sx={{ height: "100%" }}>
                    <ReportsBarChart
                      color="info"
                      title=""
                      description="Number of surgical procedures by type and year."
                      date="2024"
                      chart={surgicalDataByYear}
                      ylabel="Number of surgical procedure"
                      xlabel="Year"
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={7.5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9} lg={6}>
                  <PieChart
                    color="info"
                    title=" percentage distribution of each type of Surgical procedure"
                    description=" percentage distribution of each type of procedure out of the total"
                    date="2024"
                    chart={surgicalProcedureByPerc}
                  />
                </Grid>
                <Grid item xs={12} md={9} lg={6}>
                  <MDBox mb={3} sx={{ height: "100%" }}>
                    <StackedBarChart
                      color="info"
                      title="Average SNOT-22 scores divided into categories"
                      description="Average SNOT-22 scores divided into categories (symptoms, 
quality of life, etc.)."
                      date="2024"
                      chart={avgSnotByCategory}
                      ylabel="Average SNOT-22 scores"
                    xlabel="Symptoms"
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={7.5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9} lg={6}>
                  <LineChart
                    color="info"
                    title="Temporal trends in total SNOT-22 scores."
                    description="Temporal trends in total SNOT-22 scores."
                    date="2024"
                    chart={temporalTrendsSnot}
                    ylabel="SNOT-22 Scores"
                    xlabel="Visit Dates"
                  />
                </Grid>
                <Grid item xs={12} md={9} lg={6}>
                  <MDBox mb={3} sx={{ height: "100%" }}>
                    <ReportsBarChart
                      color="info"
                      title="Distribution of patients by medication type."
                      description="Distribution of patients by medication type."
                      date="2024"
                      chart={patientByMedication}
                      ylabel="No of Patients"
                      xlabel="Medication Type"
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={7.5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9} lg={6}>
                  <ScatterPlotChart
                    color="info"
                    title="Correlation between medication type and clinical scores"
                    description="Correlation between medication type and clinical scores (NPS, OST Test)"
                    date="2024"
                    chart={medicationCorrelation}
                    ylabel="No Of Patients"
                  />
                </Grid>
                <Grid item xs={12} md={9} lg={6}>
                  <MDBox mb={3} sx={{ height: "100%" }}>
                    <PieChart
                      color="info"
                      title="Percentage of patients by gender."
                      description="Percentage Distribution of patients by gender."
                      date="2024"
                      chart={genderStatsPatients}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={7.5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9} lg={6}>
                  <HistogramChart
                    color="info"
                    title="Age Distribution of Patients"
                    description="Histogram showing the frequency of patients across age ranges"
                    date="Updated Dec 2024"
                    chart={ageDitributionPatient}
                    xlabel="Age Range"
                    ylabel="No of Patients"
                  />
                </Grid>
                <Grid item xs={12} md={9} lg={6}>
                  <MDBox mb={3} sx={{ height: "100%" }}>
                    <BoxPlot
                      color="info"
                      title="Comparison of clinical outcomes by age group."
                      description="Comparison of clinical outcomes by age group."
                      date="Updated today"
                      chart={testScoreByAge}
                      xlabel="Age Ranges"
                      ylabel="Avg NPS and OSS Score"
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <MDBox mt={7.5}>
              <Grid container spacing={2}>
                <Grid item xs={19} md={22} lg={6}>
                  <ScatterPlotMatrixLayout />
                </Grid>
                <Grid item xs={12} md={9} lg={6}>
                  <MDBox mb={3} sx={{ height: "100%" }}></MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        )}

        {/* <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
    </DashboardLayout>
  );
}
export default Dashboard;

import React from 'react';
import { 
  Container, 
  Typography, 
  Button, 
  Box 
} from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  textAlign: 'center',
}));

const StyledImage = styled('img')({
  maxWidth: '100%',
  height: 'auto',
  marginBottom: '2rem',
});

const PageNotFound = () => {
  return (
    <div style={{ display:'flex',justifyContent:'center',alignItems:'center',height:'100vh',marginLeft:'30px'}} >
        <StyledContainer maxWidth="sm"> 
      <Typography variant="h1" component="h1" gutterBottom style={{fontSize:"117px",marginBottom:'-4px'}}>
        404
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom>
        Oops! Page Not Found
      </Typography>
      <Typography variant="body1" paragraph>
        The page you are looking for might have been removed, had its name changed, or is temporarily unavailable. 
      </Typography>
      <Box mt={4}>
          {/* <Button variant="contained" style={{backgroundColor:'#1A73E8',color:'white'}} size="large">
            Go Back
          </Button> */}
      </Box>
    </StyledContainer>
    </div>
  );
};

export default PageNotFound;

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function MDDelete({ handleDeleteDialog, deleteDialog , handleDelete }) {

  const handleClickOpen = () => {
    handleDeleteDialog(true);
  };

  const handleClose = () => {
    handleDeleteDialog(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={deleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{marginTop:'12px'}}>
        Are you sure you want to delete the Patient?
        </DialogTitle>
        <DialogActions style={{display:'flex',justifyContent:"space-around"}}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={()=>handleDelete()} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton'; // Assuming you're using Material-UI buttons (MDButton) from your project
import  toast  from 'react-hot-toast'; // For displaying success/error messages
import CircularProgress from '@mui/material/CircularProgress';

function ConsentManage() {
    const { token } = useParams(); // Token from URL
    const navigate = useNavigate();
    const [isAcceptLoad, setIsAcceptLoad] = useState(false); // Loading state
    const [isRejectLoad, setIsRejectLoad,] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    // Function to handle consent decision (accept/reject)
    const handleConsentDecision = (decision) => {
        if (decision==="reject"){
            setIsRejectLoad(true);
        }

        if (decision==="accept"){
            setIsAcceptLoad(true);
        }

        fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/consent/${token}`, {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "X-Custom-Header": "header value",
            },
            body: JSON.stringify({ decision }),
          })
        .then((response) => response.json())
        .then((data) => {
            if (data.success) {
                toast.success(`Consent ${decision}ed successfully.`);
                setIsAcceptLoad(false)
                setIsRejectLoad(false)
                setResponseMessage('Response has been submitted successfully!');
            } else {
                toast.error(data.message || 'Something went wrong.');
                setIsAcceptLoad(false)
                setIsRejectLoad(false)
            }
        })
        .catch(() => {            
            toast.error('Error handling consent. Please try again later.');
            setIsAcceptLoad(false)
            setIsRejectLoad(false)
        });
    };

    return (
        <Card sx={{ padding: 4, maxWidth: 600, margin: 'auto', marginTop: '50px' }}>

{responseMessage ? (
     <MDBox mb={3}>
    <MDTypography variant="h4" textAlign="center">
        {responseMessage}
    </MDTypography>
     </MDBox>
    ) :
        <>
            <MDBox mb={3}>
                <MDTypography variant="h4" textAlign="center">
                    Consent for Patient Treatment
                </MDTypography>
            </MDBox>
            <MDBox mb={3}>
                <MDTypography variant="body1" textAlign="center">
                    We request your consent to proceed with your treatment. By accepting, you allow us to move forward with your medical care. You can choose to either accept or reject this consent.
                </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="center" gap={2}>
                <MDButton
                    color="success"
                    disabled={isAcceptLoad}
                    onClick={() => handleConsentDecision('accept')}
                    >
                    {isAcceptLoad ?       <div className="loader_wrapper">
                  <CircularProgress style={{height:'20px'}}/> 
                </div> : 'Accept'}
                </MDButton>
                <MDButton
                    color="error"
                    disabled={isRejectLoad}
                    onClick={() => handleConsentDecision('reject')}
                    >
                    {isRejectLoad ?       <div className="loader_wrapper">
                  <CircularProgress style={{height:'20px'}}/> 
                </div> : 'Reject'}
                </MDButton>
            </MDBox>
            </>
            }
        </Card>
    );
}

export default ConsentManage;

import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Icon from "@mui/material/Icon";
import { useContext } from "react";
import { AuthContext } from 'context';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

const headers = [
  "Serial No",
  "Name",
  "Email",
  "specialization",
  "city",
  "Price",
  // "Actions",
];

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/;

function PhysiciansTables() {
  const [tableData, setTableData] = useState({ columns: [], rows: [] });

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [viewOpen, setViewOpen] = useState(false);
  const [newPhysiciansData, setNewPhysiciansData] = useState({});
  const [editPhysicianData, setEditPhysicianData] = useState({});
  const [viewPhysicianData, setViewPhysicianData] = useState({});
  const [responseError, setResponseError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [editFormErrors, setEditFormErrors] = useState({});
  const [isPhysicianLoading, setIsPhysicianLoading] = useState(false)
  const { userData: { role, token } } = useContext(AuthContext);
  const navigate = useNavigate();

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  useEffect(() => {
    if (!token) {
      navigate('/authentication/sign-in');
    }
    fetchPhysicians();
  }, []);

  const fetchPhysicians = () => {
    setIsPhysicianLoading(true)
    fetch( `https://${process.env.REACT_APP_API_URL}/api/physician/get-all`, {
      method: 'GET',
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "X-Custom-Header": "header value",
        },
    })
      .then((res => res.json()))
      .then(data => {
        if (data.length > 0) {
          const tempColumnsArray = Object.keys(data[0]);
          const tempColumns = tempColumnsArray.map((obj, i) => ({
            Header: headers[i],
            accessor: obj,
            width: "14%",
            align: "left"
          }));
          // tempColumns.push({
          //   Header: "Actions",
          //   accessor: "actions",
          //   width: "14%",
          //   align: "center"
          // });
          setTableData(() => ({ columns: tempColumns, rows: data }));
          setIsPhysicianLoading(false)
        }
        setIsPhysicianLoading(false)
      })
      .catch(() => {
        setIsPhysicianLoading(false)
        setResponseError("Error fetching data");
        openErrorSB();
      });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleEditOpen = (physician) => {
    setEditPhysicianData(physician);
    setEditOpen(true);
  };
  const handleEditClose = () => setEditOpen(false);

  const handleViewClose = () => setViewOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPhysiciansData(prevState => ({ ...prevState, [name]: value }));

    // Validate fields on change
    validateField(name, value);
  }

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditPhysicianData(prevState => ({ ...prevState, [name]: value }));

    // Validate fields on change
    validateEditField(name, value);
  }

  const validateField = (name, value) => {
    let errors = {};
    if (name === "email" && !emailRegex.test(value)) {
      errors.email = "Invalid email format";
    }
    if (name === "password" && !passwordRegex.test(value)) {
      errors.password = "Password must be 8-16 characters long and include an uppercase letter, a lowercase letter, a digit, and a special character";
    }
    if (name === "name" && !value) {
      errors.name = "Name is required";
    }
    if (name === "mobileNumber" && !value) {
      errors.mobileNumber = "Mobile Number is required";
    }
    if (name === "department" && !value) {
      errors.department = "Department is required";
    }
    if (name === "." && !value) {
      errors.price = "Price is required";
    }
    setFormErrors(errors);
  }

  const validateEditField = (name, value) => {
    let errors = {};
    if (name === "email" && !emailRegex.test(value)) {
      errors.email = "Invalid email format";
    }
    if (name === "name" && !value) {
      errors.name = "Name is required";
    }
    if (name === "mobileNumber" && !value) {
      errors.mobileNumber = "Mobile Number is required";
    }
    if (name === "department" && !value) {
      errors.department = "Department is required";
    }
    if (name === "price" && !value) {
      errors.price = "Price is required";
    }
    setEditFormErrors(errors);
  }

  const handleAddPhysician = () => {
    const errors = {};
    if (!newPhysiciansData.name) errors.name = "Name is required";
    if (!emailRegex.test(newPhysiciansData.email)) errors.email = "Invalid email format";
    if (!passwordRegex.test(newPhysiciansData.password)) errors.password = "Password must be 8-16 characters long and include an uppercase letter, a lowercase letter, a digit, and a special character";
    if (!newPhysiciansData.department) errors.department = "Department is required";
    if (!newPhysiciansData.mobileNumber) errors.mobileNumber = "Mobile Number is required";
    if (!newPhysiciansData.price) errors.price = "Price is required";
    
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    fetch(`https://${process.env.REACT_APP_API_URL}/api/auth/register`, {
      method: 'POST',
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "X-Custom-Header": "header value",
          },
      body: JSON.stringify(newPhysiciansData)
    })
      .then((res => res.json()))
      .then(data => {
        if (data.success) {
          openSuccessSB();
          fetchPhysicians();
          handleClose();
        } else {
          if (data.message) {
            setResponseError(data.message);
            openErrorSB();
          }
        }
      })
      .catch(() => {
        setResponseError("Error adding physician");
        openErrorSB();
      });
  }

  const handleEditPhysician = () => {
    const errors = {};
    if (!editPhysicianData.name) errors.name = "Name is required";
    if (!emailRegex.test(editPhysicianData.email)) errors.email = "Invalid email format";
    if (!editPhysicianData.department) errors.department = "Department is required";
    if (!editPhysicianData.mobileNumber) errors.mobileNumber = "Mobile Number is required";
    if (!editPhysicianData.price) errors.price = "Price is required";
    
    if (Object.keys(errors).length > 0) {
      setEditFormErrors(errors);
      return;
    }

    fetch(`https://${process.env.REACT_APP_API_URL}/api/physician/update/${editPhysicianData._id}`, {
      method: 'PUT',
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "X-Custom-Header": "header value",
          },
      body: JSON.stringify(editPhysicianData)
    })
      .then((res => res.json()))
      .then(data => {
        if (data._id) {
          openSuccessSB();
          fetchPhysicians();
          handleEditClose();
        } else if (data.message) {
          setResponseError(data.message);
          openErrorSB();
        }
      })
      .catch(() => {
        setResponseError("Error updating physician");
        openErrorSB();
      });
  }

  const handleDeletePhysician = (id) => {
    fetch(`https://${process.env.REACT_APP_API_URL}/api/physician/delete/${id}`, {
      method: 'DELETE',
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "X-Custom-Header": "header value",
          },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === 'Physician deleted successfully') {
          openSuccessSB();
          setTableData((prevState) => ({
            ...prevState,
            rows: prevState.rows.filter((row) => row._id !== id)
          }));
        } else {
          setResponseError(data.message || "Error deleting physician");
          openErrorSB();
        }
      })
      .catch(() => {
        setResponseError("Error deleting physician");
        openErrorSB();
      });
  };

  const renderRows = () => {
    return tableData.rows.map((row, index) => ({
      ...row,
      actions: (
        <MDBox display="flex" justifyContent="space-evenly">
          <MDButton onClick={() => handleEditOpen(row)}>
            <Icon color="success">edit</Icon>
          </MDButton>
          <MDButton onClick={() => handleDeletePhysician(row._id)}>
            <Icon color="error">delete</Icon>
          </MDButton>
        </MDBox>
      )
    }));
  }

  return (
    <DashboardLayout>
      <MDSnackbar
        color="success"
        icon="check"
        title="Success"
        content="Operation completed successfully"
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
      />
      <MDSnackbar
        color="error"
        icon="error"
        title="Error"
        content={responseError}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Physicians Table
                </MDTypography>
                {/* <MDButton onClick={handleOpen}>
                  <Icon>add</Icon> Add Physician
                </MDButton> */}
              </MDBox>
              <MDBox pt={3}>
               {!isPhysicianLoading ?  
               tableData.rows.length > 0 ? 
               <DataTable
                  table={{ ...tableData, rows: renderRows() }}
                  isSorted={true}
                  entriesPerPage={2}
                  showTotalEntries={true}
                  noEndBorder
                /> : 
                <MDTypography  
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingBottom: "30px",
                }} >
                    No Physicians Present
                </MDTypography> 
                : 
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "30px",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </>}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add-modal-title"
        aria-describedby="add-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: 2,
            boxShadow: 5,
            padding: 4,
            width: 400,
            textAlign: 'center',
          }}
        >
          <MDBox>
            <MDTypography display="block" variant="button" color="red" my={1} px={3}>
              {formErrors.general}
            </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Name"
              name="name"
              variant="standard"
              fullWidth
              onChange={handleChange}
              error={!!formErrors.name}
              helperText={formErrors.name}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="email"
              label="Email"
              name="email"
              variant="standard"
              fullWidth
              onChange={handleChange}
              error={!!formErrors.email}
              helperText={formErrors.email}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="password"
              label="Password"
              name="password"
              variant="standard"
              fullWidth
              onChange={handleChange}
              error={!!formErrors.password}
              helperText={formErrors.password}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Department"
              name="department"
              variant="standard"
              fullWidth
              onChange={handleChange}
              error={!!formErrors.department}
              helperText={formErrors.department}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Phone"
              name="mobileNumber"
              variant="standard"
              fullWidth
              onChange={handleChange}
              error={!!formErrors.mobileNumber}
              helperText={formErrors.mobileNumber}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Price"
              name="price"
              variant="standard"
              fullWidth
              onChange={handleChange}
              error={!!formErrors.price}
              helperText={formErrors.price}
            />
          </MDBox>
          <Button onClick={handleAddPhysician} sx={{ mt: 2 }} color="primary">
            Add
          </Button>
          <Button onClick={handleClose} sx={{ mt: 2 }} color="secondary">
            Close
          </Button>
        </Box>
      </Modal>

      <Modal
        open={editOpen}
        onClose={handleEditClose}
        aria-labelledby="edit-modal-title"
        aria-describedby="edit-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: 2,
            boxShadow: 5,
            padding: 4,
            width: 400,
            textAlign: 'center',
          }}
        >
          <MDBox>
            <MDTypography display="block" variant="button" color="red" my={1} px={3}>
              {editFormErrors.general}
            </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Name"
              name="name"
              variant="standard"
              fullWidth
              value={editPhysicianData.name || ''}
              onChange={handleEditChange}
              error={!!editFormErrors.name}
              helperText={editFormErrors.name}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="email"
              label="Email"
              name="email"
              variant="standard"
              fullWidth
              value={editPhysicianData.email || ''}
              onChange={handleEditChange}
              error={!!editFormErrors.email}
              helperText={editFormErrors.email}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Department"
              name="department"
              variant="standard"
              fullWidth
              value={editPhysicianData.department || ''}
              onChange={handleEditChange}
              error={!!editFormErrors.department}
              helperText={editFormErrors.department}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Phone"
              name="mobileNumber"
              variant="standard"
              fullWidth
              value={editPhysicianData.mobileNumber || ''}
              onChange={handleEditChange}
              error={!!editFormErrors.mobileNumber}
              helperText={editFormErrors.mobileNumber}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Price"
              name="price"
              variant="standard"
              fullWidth
              value={editPhysicianData.price || ''}
              onChange={handleEditChange}
              error={!!editFormErrors.price}
              helperText={editFormErrors.price}
            />
          </MDBox>
          <Button onClick={handleEditPhysician} sx={{ mt: 2 }} color="primary">
            Save
          </Button>
          <Button onClick={handleEditClose} sx={{ mt: 2 }} color="secondary">
            Close
          </Button>
        </Box>
      </Modal>

      <Modal
        open={viewOpen}
        onClose={handleViewClose}
        aria-labelledby="view-modal-title"
        aria-describedby="view-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: 2,
            boxShadow: 5,
            padding: 4,
            width: 400,
            textAlign: 'center',
          }}
        >
          <MDBox mb={2}>
            <MDTypography display="block" variant="button" my={1} px={3}>
              Name: {viewPhysicianData.name}
            </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDTypography display="block" variant="button" my={1} px={3}>
              Email: {viewPhysicianData.email}
            </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDTypography display="block" variant="button" my={1} px={3}>
              Department: {viewPhysicianData.department}
            </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDTypography display="block" variant="button" my={1} px={3}>
              Phone: {viewPhysicianData.mobileNumber}
            </MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <MDTypography display="block" variant="button" my={1} px={3}>
              Price: {viewPhysicianData.price}
            </MDTypography>
          </MDBox>
          <Button onClick={handleViewClose} sx={{ mt: 2 }} color="secondary">
            Close
          </Button>
        </Box>
      </Modal>
    </DashboardLayout>
  );
};

export default PhysiciansTables;

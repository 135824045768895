import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "context";
import {
  Box,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Grid,
  Card,
  Modal,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MDSlider from "components/MDSlider/MDSlider";
import MDTypography from "components/MDTypography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import MDBox from "components/MDBox";
import "./MyForm.css";

const symptomsOptions = {
  nasalObstructionSeverity: ["Lieve", "Moderata", "Severa"],
  nasalObstructionLaterality: ["Unilaterale", "Bilaterale"],
  rhinorrheaPresenceOptions: ["Yes", "No"],
  rhinorrheaSecretion: ["Anteriore", "Posteriore", "AnteroPosteriore"],
  rhinorrheaCharacteristics: ["Acquosa", "Mucosa", "Purulenta"],
  reductionInSmell: ["Hyposmia", "Anosmia"],
  reductionInTaste: ["Hypogeusia", "Ageusia"],
  painPresence: ["Yes", "No"],
  painFrequency: ["Sporadic", "Intermittent", "Continuous"],
  otherSymptoms: ["Disturbi del Sonno", "Prurito Nasale", "Starnuti"],
};



const PositiveNumberField = ({ field, form, ...props }) => {
  const handleChange = (event) => {
    let value = event.target.value;

    // Ensure value is not negative
    if (value < 0) {
      value = 0;
    }

    form.setFieldValue(field.name, value);
  };

  return <input {...field} {...props} type="number" onChange={handleChange} />;
};


const MDReportForm = ({
  AddPatientReports,
  reportData,
  isSaveData,
  isDisabled = false,
  submitRef,
  snotValue
}) => {

  console.log(reportData,'reportData ');

  const [formData, setFormData] = useState({
    nasalObstructionSeverity: "",
    nasalObstructionLaterality: "",
    rhinorrheaPresence: "",
    rhinorrheaSecretion: "",
    rhinorrheaCharacteristics: "",
    hyposmiaPresence: "",
    hyposmiaLevel: 0,
    reductionInSmellDuration: "",
    reductionInTastePresence: "",
    reductionInTasteLevel: 0,
    reductionInTasteDuration: "",
    painPresence: "",
    painIntensity: 0,
    painFrequency: "",
    otherSymptoms: [],
    snot22Score: snotValue,
    igeValue: "",
    eosinophilCount: "",
    nasalCytologyEosinophilCount: "",
    tissueEosinophilCount: "",
  });
  console.log(snotValue,'snotValue in report form');

  const {
    userData: { role },
  } = useContext(AuthContext);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (reportData) {
      setFormData({...reportData,snot22Score:snotValue});
    }
  }, [reportData]);

  // useEffect(() => {
  //   console.log(formData,'formData/formData');    
  // }, [formData]);


  useEffect(()=>{
    if (reportData){
      setFormData({...reportData,snot22Score:snotValue })  
    }
    else{
      setFormData({...formData,snot22Score:snotValue })
    }
  },[snotValue])

  useEffect(() => {
    console.log(submitRef.current,'submitRef.current');
    if (submitRef.current) {
      console.log(submitRef.current,'submitRef.current //');
      handleSubmit();
    }
  }, [submitRef.current]);


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [name]: checked
          ? [...prev[name], value]
          : prev[name].filter((v) => v !== value),
      }));
    } 
    else if (type === "number"){
      if (value === "" || /^[0-9]*$/.test(value)){
        setFormData((prev) => ({ ...prev, [name]: value }));
      }
    }
    else  {
      setFormData((prev) => ({ ...prev, [name]: value }));
      // setFormErrors((prevErrors) => ({
      //   ...prevErrors,
      //   [name]: validateField(name, value),
      // }));
    }
  };

  // const handleBlur = (e) => {
  //   const { name, value } = e.target;
  //   // Validate field on blur
  //   // setFormErrors((prevErrors) => ({
  //   //   ...prevErrors,
  //   //   [name]: validateField(name, value),
  //   // }));
  // };

  // const validateForm = () => {
  //   const errors = {};
  //   Object.keys(formData).forEach((key) => {
  //     const error = validateField(key, formData[key]);
  //     if (error) errors[key] = error;
  //   });
  //   setFormErrors(errors);
  //   return Object.keys(errors).length === 0;
  // };

  const handleSubmit = (e) => {
    e?.preventDefault();
    // if (validateForm()) {
      AddPatientReports(formData);
    //   setFormData({
    //     nasalObstructionSeverity: "",
    //     nasalObstructionLaterality: "",
    //     rhinorrheaPresence: "",
    //     rhinorrheaSecretion: "",
    //     rhinorrheaCharacteristics: "",
    //     hyposmiaPresence: "",
    //     hyposmiaLevel: 0,
    //     reductionInSmellDuration: "",
    //     reductionInTastePresence: "",
    //     reductionInTasteLevel: 0,
    //     reductionInTasteDuration: "",
    //     painPresence: "",
    //     painIntensity: 0,
    //     painFrequency: "",
    //     otherSymptoms: [],
    //     snot22Score: "",
    //     igeValue: "",
    //     eosinophilCount: "",
    //     nasalCytologyEosinophilCount: "",
    //     tissueEosinophilCount: "",
    //   });
    // }
  };

  const handleUpdate = () => {
    // if (validateForm()) {
        AddPatientReports(formData);
    //   setFormData({
    //     nasalObstructionSeverity: "",
    //     nasalObstructionLaterality: "",
    //     rhinorrheaPresence: "",
    //     rhinorrheaSecretion: "",
    //     rhinorrheaCharacteristics: "",
    //     hyposmiaPresence: "",
    //     hyposmiaLevel: 0,
    //     reductionInSmellDuration: "",
    //     reductionInTastePresence: "",
    //     reductionInTasteLevel: 0,
    //     reductionInTasteDuration: "",
    //     painPresence: "",
    //     painIntensity: 0,
    //     painFrequency: "",
    //     otherSymptoms: [],
    //     snot22Score: "",
    //     igeValue: "",
    //     eosinophilCount: "",
    //     nasalCytologyEosinophilCount: "",
    //     tissueEosinophilCount: "",
    //   });
    // }
  };

  const handlecloseAll = () => {
    // setFormErrors({});
    setFormData({
      nasalObstructionSeverity: "",
      nasalObstructionLaterality: "",
      rhinorrheaPresence: "",
      rhinorrheaSecretion: "",
      rhinorrheaCharacteristics: "",
      hyposmiaPresence: "",
      hyposmiaLevel: 0,
      reductionInSmellDuration: "",
      reductionInTastePresence: "",
      reductionInTasteLevel: 0,
      reductionInTasteDuration: "",
      painPresence: "",
      painIntensity: 0,
      painFrequency: "",
      otherSymptoms: [],
      snot22Score: "",
      igeValue: "",
      eosinophilCount: "",
      nasalCytologyEosinophilCount: "",
      tissueEosinophilCount: "",
    });
  };

  const handleSliderChange = (name) => (event, newValue) => {
    setFormData((prev) => ({ ...prev, [name]: newValue }));
  };

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Patient Medical Report
              </MDTypography>
            </MDBox>

            <MDBox pt={3} pb={0}>
              <>
                <Box
                  component="form"
                  className="form-container"
                  onSubmit={handleSubmit}
                >
                  <Grid container spacing={3}>
                    {/* Symptoms Section */}
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ textAlign: "start" }}
                      >
                        Nasal Obstruction
                      </Typography>
                      {/* Nasal Obstruction */}
                      <div style={{position:'relative'}}>

                        <TextField
                          select
                          label="Nasal Obstruction Severity"
                          name="nasalObstructionSeverity"
                          value={formData.nasalObstructionSeverity}
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          fullWidth
                          sx={{ mb: 4 }}
                          InputProps={{
                            sx: {
                              lineHeight: "3em",
                            },
                          }}
                          // error={!!formErrors.nasalObstructionSeverity}
                          // helperText={formErrors.nasalObstructionSeverity}
                          disabled={isDisabled}
                        >
                          {symptomsOptions.nasalObstructionSeverity.map(
                            (option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                        <ArrowDropDownIcon style={{ position:"absolute",right:'10px',top:'10px' }}/>
                      </div>

                          <div style={{position:'relative'}}>
                        <TextField
                          select
                          label="Nasal Obstruction Laterality"
                          name="nasalObstructionLaterality"
                          value={formData.nasalObstructionLaterality}
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          fullWidth
                          sx={{ mb: 4 }}
                          InputProps={{
                            sx: {
                              lineHeight: "3em", // Adjust line height as needed
                            },
                          }}
                          // error={!!formErrors.nasalObstructionLaterality}
                          // helperText={formErrors.nasalObstructionLaterality}
                          disabled={isDisabled}
                        >
                          {symptomsOptions.nasalObstructionLaterality.map(
                            (option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                        <ArrowDropDownIcon style={{ position:"absolute",right:'10px',top:'10px' }}/>
                        </div>

                        {/* Rhinorrhea Section */}
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          sx={{ textAlign: "start" }}
                        >
                          Rinorrea (Rhinorrhea)
                        </Typography>

                        {/* Rhinorrhea Presence */}
                        <div style={{position:'relative'}}>
                        <TextField
                          select
                          label="Rhinorrhea Presence"
                          name="rhinorrheaPresence"
                          value={formData.rhinorrheaPresence}
                          onChange={handleChange}
                          fullWidth
                          sx={{ mb: 4 }}
                          // error={!!formErrors.rhinorrheaPresence}
                          // helperText={formErrors.rhinorrheaPresence}
                          InputProps={{
                            sx: {
                              lineHeight: "3em",
                            },
                          }}
                          disabled={isDisabled}
                        >
                          {symptomsOptions.rhinorrheaPresenceOptions.map(
                            (option) => (
                              <MenuItem key={option} value={option}>
                                {option}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                        <ArrowDropDownIcon style={{ position:"absolute",right:'10px',top:'10px' }}/>
                        </div>

                        {/* Conditional fields (only show when rhinorrheaPresence is "Yes") */}
                        {formData.rhinorrheaPresence === "Yes" && (
                          <>
                          <div style={{position:'relative'}}>
                            <TextField
                              select
                              label="Rhinorrhea Secretion"
                              name="rhinorrheaSecretion"
                              value={formData.rhinorrheaSecretion}
                              onChange={handleChange}
                              fullWidth
                              sx={{ mb: 4 }}
                              // error={!!formErrors.rhinorrheaSecretion}
                              // helperText={formErrors.rhinorrheaSecretion}
                              InputProps={{
                                sx: {
                                  lineHeight: "3em",
                                },
                              }}
                              disabled={isDisabled}
                            >
                              {symptomsOptions.rhinorrheaSecretion.map(
                                (option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                            <ArrowDropDownIcon style={{ position:"absolute",right:'10px',top:'10px' }}/>
                            </div>
                            <div style={{position:'relative'}}>
                            <TextField
                              select
                              label="Rhinorrhea Characteristics"
                              name="rhinorrheaCharacteristics"
                              value={formData.rhinorrheaCharacteristics}
                              onChange={handleChange}
                              fullWidth
                              sx={{ mb: 4 }}
                              // error={!!formErrors.rhinorrheaCharacteristics}
                              // helperText={formErrors.rhinorrheaCharacteristics}
                              InputProps={{
                                sx: {
                                  lineHeight: "3em",
                                },
                              }}
                              disabled={isDisabled}
                            >
                              {symptomsOptions.rhinorrheaCharacteristics.map(
                                (option) => (
                                  <MenuItem key={option} value={option}>
                                    {option}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                            <ArrowDropDownIcon style={{ position:"absolute",right:'10px',top:'10px' }}/>
                            </div>
                          </>
                        )}

                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          sx={{ textAlign: "start" }}
                        >
                          Iposmia/Anosmia (Hyposmia/Anosmia)
                        </Typography>
                        {/* Hyposmia Section */}
                        <Grid item xs={12}>
                          {/* Hyposmia Yes/No */}
                          <div style={{position:'relative'}}>
                          <FormControl fullWidth sx={{ mb: 4 }}>
                            <InputLabel>Hyposmia Presence</InputLabel>
                            <Select
                              name="hyposmiaPresence"
                              label="Hyposmia Presence"
                              value={formData.hyposmiaPresence}
                              onChange={handleChange}
                              // onBlur={handleBlur}
                              // error={!!formErrors.hyposmiaPresence}
                              sx={{
                                lineHeight: "3em",
                              }}
                              disabled={isDisabled}
                            >
                              <MenuItem value="Yes">Yes</MenuItem>
                              <MenuItem value="No">No</MenuItem>
                            </Select>
                            {/* <FormHelperText
                              error={!!formErrors.hyposmiaPresence}
                            >
                              {formErrors.hyposmiaPresence}
                            </FormHelperText> */}
                          </FormControl>
                          <ArrowDropDownIcon style={{ position:"absolute",right:'10px',top:'10px' }}/>
                          </div>


                          {/* Hyposmia Slider */}
                          {formData.hyposmiaPresence === "Yes" && (
                            <Box sx={{ mb: 4, display: "flex", gap: "100px" }}>
                              <Typography variant="body2" gutterBottom>
                                Hyposmia Level
                              </Typography>
                              <MDSlider
                                value={formData.hyposmiaLevel}
                                onChange={handleSliderChange("hyposmiaLevel")}
                                min={0}
                                max={10}
                                step={1}
                                marks={[
                                  { value: 0, label: "Normosmia" },
                                  { value: 10, label: "Anosmia" },
                                ]}
                                label0Text="Normosmia"
                                label10Text="Ageusic"
                                disabled={isDisabled}
                              />
                            </Box>
                          )}
                        </Grid>

                        {/* Duration of Hyposmia */}
                        <Grid item xs={12}>
                        <div style={{position:'relative'}}>
                          <TextField
                            select
                            label="Duration of Hyposmia/Anosmia"
                            name="reductionInSmellDuration"
                            value={formData.reductionInSmellDuration}
                            onChange={handleChange}
                            // onBlur={handleBlur}
                            fullWidth
                            sx={{ mb: 4 }}
                            InputProps={{
                              sx: {
                                lineHeight: "3em",
                              },
                            }}
                            // error={!!formErrors.reductionInSmellDuration}
                            // helperText={formErrors.reductionInSmellDuration}
                            disabled={isDisabled}
                          >
                            <MenuItem value="Short">
                              Short (less than 1 month)
                            </MenuItem>
                            <MenuItem value="Medium">
                              Medium (1-6 months)
                            </MenuItem>
                            <MenuItem value="Long">
                              Long (over 6 months)
                            </MenuItem>
                          </TextField>

                          <ArrowDropDownIcon style={{ position:"absolute",right:'10px',top:'10px' }}/>
                        </div>

                        </Grid>

                        <div style={{position:'relative'}}>
                        <FormControl fullWidth sx={{ mb: 4 }}>
                          <InputLabel>Reduction in Taste Presence</InputLabel>
                          <Select
                            name="reductionInTastePresence"
                            value={formData.reductionInTastePresence}
                            label="Reduction in Taste Presence"
                            onChange={handleChange}
                            // onBlur={handleBlur}
                            // error={!!formErrors.reductionInTastePresence}
                            sx={{
                              lineHeight: "3em",
                            }}
                            disabled={isDisabled}
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                          {/* <FormHelperText
                            error={!!formErrors.reductionInTastePresence}
                          >
                            {formErrors.reductionInTastePresence}
                          </FormHelperText> */}
                        </FormControl>
                        <ArrowDropDownIcon style={{ position:"absolute",right:'10px',top:'10px' }}/>
                        </div>

                        {formData.reductionInTastePresence === "Yes" && (
                          <Box sx={{ mb: 4, display: "flex", gap: "100px" }}>
                            <Typography
                              variant="body2"
                              gutterBottom
                              sx={{ textAlign: "start" }}
                            >
                              Level of Taste Reduction
                            </Typography>
                            <MDSlider
                              value={formData.reductionInTasteLevel}
                              onChange={handleSliderChange(
                                "reductionInTasteLevel"
                              )}
                              aria-label="Taste Reduction Level"
                              min={0}
                              max={10}
                              step={1}
                              marks={[
                                { value: 0, label: "Normogeusia" },
                                { value: 10, label: "Ageusia" },
                              ]}
                              label0Text="Normogeusia"
                              label10Text="Ageusic"
                              disabled={isDisabled}
                            />
                          </Box>
                        )}
   <div style={{position:'relative'}}>
                        <TextField
                          select
                          label="Duration of Reduction in Taste"
                          name="reductionInTasteDuration"
                          value={formData.reductionInTasteDuration}
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          fullWidth
                          sx={{ mb: 4 }}
                          InputProps={{
                            sx: {
                              lineHeight: "3em",
                            },
                          }}
                          // error={!!formErrors.reductionInTasteDuration}
                          // helperText={formErrors.reductionInTasteDuration}
                          disabled={isDisabled}
                        >
                          <MenuItem value="Short">
                            Short (less than 1 month)
                          </MenuItem>
                          <MenuItem value="Medium">
                            Medium (1-6 months)
                          </MenuItem>
                          <MenuItem value="Long">Long (over 6 months)</MenuItem>
                        </TextField>
                        <ArrowDropDownIcon style={{ position:"absolute",right:'10px',top:'10px' }}/>
                        </div>

                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          sx={{ textAlign: "start" }}
                        >
                          Dolore/Pressione Facciale (Pain/Facial Pressure)
                        </Typography>
                        {/* Pain/Facial Pressure */}
                        <div style={{position:'relative'}}>
                        <TextField
                          select
                          label="Pain Presence"
                          name="painPresence"
                          value={formData.painPresence}
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          fullWidth
                          sx={{ mb: 4 }}
                          InputProps={{
                            sx: {
                              lineHeight: "3em",
                            },
                          }}
                          // error={!!formErrors.painPresence}
                          // helperText={formErrors.painPresence}
                          disabled={isDisabled}
                        >
                          {symptomsOptions.painPresence.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                        <ArrowDropDownIcon style={{ position:"absolute",right:'10px',top:'10px' }}/>
                        </div>

                        <Box sx={{ mb: 4, display: "flex", gap: "100px" }}>
                          <Typography
                            variant="body2"
                            gutterBottom
                            sx={{ textAlign: "start" }}
                          >
                            Pain Intensity
                          </Typography>
                          <MDSlider
                            value={formData.painIntensity}
                            onChange={handleSliderChange("painIntensity")}
                            aria-label="Pain Intensity"
                            min={0}
                            max={10}
                            step={1}
                            marks={[
                              { value: 0, label: "No pain" },
                              { value: 10, label: "Severe pain" },
                            ]}
                            label0Text="No pain"
                            label10Text="Severe pain"
                            disabled={isDisabled}
                          />
                        </Box>
                        <div style={{position:'relative'}}>
                        <TextField
                          select
                          label="Pain Frequency"
                          name="painFrequency"
                          value={formData.painFrequency}
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          fullWidth
                          sx={{ mb: 4 }}
                          InputProps={{
                            sx: {
                              lineHeight: "3em", // Adjust line height as needed
                            },
                          }}
                          // error={!!formErrors.painFrequency}
                          // helperText={formErrors.painFrequency}
                          disabled={isDisabled}
                        >
                          {symptomsOptions.painFrequency.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </TextField>
                        <ArrowDropDownIcon style={{ position:"absolute",right:'10px',top:'10px' }}/>
                        </div>
                      {/* Other Symptoms */}
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          sx={{ textAlign: "start" }}
                        >
                          Altri Sintomi (Other Symptoms)
                        </Typography>
                        <Box style={{ display: "flex", gap: "20px" }}>
                          {symptomsOptions.otherSymptoms.map((option) => (
                            <FormControlLabel
                              key={option}
                              control={
                                <Checkbox
                                  name="otherSymptoms"
                                  value={option}
                                  checked={formData?.otherSymptoms?.includes(
                                    option
                                  )}
                                  onChange={handleChange}
                                  disabled={isDisabled}
                                />
                              }
                              label={option}
                            />
                          ))}
                        </Box>
                      <br />

                      {/* Medical Parameters */}

                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ textAlign: "start" }}
                      >
                        Medical Parameters
                      </Typography>
                      <TextField
                        label="SNOT22 Score"
                        name="snot22Score"
                        type="number"
                        value={formData.snot22Score}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        fullWidth
                        sx={{ mb: 2 }}
                        // error={!!formErrors.snot22Score}
                        // helperText={formErrors.snot22Score}
                        disabled={isDisabled}
                      />

                      <TextField
                        label="IgE Value (IU/mL)"
                        name="igeValue"
                        type="number"
                        value={formData.igeValue}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        fullWidth
                        sx={{ mb: 2 }}
                        // error={!!formErrors.igeValue}
                        // helperText={formErrors.igeValue}
                        disabled={isDisabled}
                      />

                      <TextField
                        label="Eosinophil Count (cells/µL)"
                        name="eosinophilCount"
                        type="number"
                        value={formData.eosinophilCount}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        fullWidth
                        sx={{ mb: 2 }}
                        // error={!!formErrors.eosinophilCount}
                        // helperText={formErrors.eosinophilCount}
                        disabled={isDisabled}
                      />

                      <TextField
                        label="Nasal Cytology Eosinophil Count (%)"
                        name="nasalCytologyEosinophilCount"
                        type="number"
                        value={formData.nasalCytologyEosinophilCount}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        fullWidth
                        sx={{ mb: 2 }}
                        // error={!!formErrors.nasalCytologyEosinophilCount}
                        // helperText={formErrors.nasalCytologyEosinophilCount}
                        disabled={isDisabled}
                      />

                      <TextField
                        label="Tissue Eosinophil Count (cells/hpf)"
                        name="tissueEosinophilCount"
                        type="number"
                        value={formData.tissueEosinophilCount}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        fullWidth
                        sx={{ mb: 2 }}
                        // error={!!formErrors.tissueEosinophilCount}
                        // helperText={formErrors.tissueEosinophilCount}
                        disabled={isDisabled}
                      />
                    </Grid>

                    {/* {role === "physician" &&
                      new URL(window.location.href).pathname !==
                        "/hospital-wide-data" && (
                        <Grid item xs={12}>
                          {reportData !== "" ? (
                            <Button
                              variant="contained"
                              color="primary"
                              style={{ color: "white" }}
                              onClick={() => handleUpdate()}
                            >
                              Update
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              style={{ color: "white" }}
                            >
                              Save
                            </Button>
                          )}
                          {"   "}
                          {reportData !== "" && (
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              style={{ color: "white" }}
                              onClick={handlecloseAll}
                            >
                              cancel
                            </Button>
                          )}
                        </Grid>
                      )} */}
                  </Grid>
                </Box>
              </>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default MDReportForm;

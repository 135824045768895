import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";


const decode = (token) => {
    if (!token || token.split(".").length !== 3) {
        console.log("comin inside this ");
      return undefined;
    }
    try {
      const decdodedData = jwtDecode(token);
      if (!decdodedData) {
        throw new Error("Token is not valid");
      }
      return decdodedData;
    } catch (error) {
      console.log(error, "showt the decode error......");
      return null;
    }
  };

const verifyToken = (token) => {
    try {
      if (!token) {
        return false;
      }
      if (decode(token)) {
        // const isExpired = decode(token)?.exp * 1000 < new Date();
        // if (isExpired) {
        //   return false;
        // }
        return true;
      } else {
        console.log("comin in fallll");
        return false;
      }
    } catch (error) {
        console.log('comin in catch');
      console.log(error);
      // navigate("/login");
      return error;
    }
  };


const PrivateRoute = ({ children }) => {
  const isLoggedIn = localStorage.getItem("token");
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (!verifyToken(isLoggedIn)) {
      return navigate("/log-out");
    } else {
      setIsCheckingAuth(false);
    }
  }, [isCheckingAuth,navigate]);
  if (isCheckingAuth) {
    return null;
  }
  return <div>{children}</div>;
};
export default PrivateRoute;
import { useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { useContext } from "react";
import { AuthContext } from 'context';
import toast from "react-hot-toast";
import CircularProgress from '@mui/material/CircularProgress';

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading,setIsLoading] = useState(false)
  const { userData: { role, token } } = useContext(AuthContext);
  const navigate = useNavigate();


  // useEffect(() => {
  //   if (!token) {
  //     navigate('/authentication/sign-in');
  //   }
  // }, []);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      const response = await fetch( `https://${process.env.REACT_APP_API_URL}/api/auth/forgot-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (response.ok) {
        setIsLoading(false);
        setResponseMessage(data.message);
        toast.success(data.message);
        setTimeout(() => {
          navigate("/authentication/sign-in");
        }, 2000);
      } else {
        setIsLoading(false);
        // setResponseMessage(data.message);
        toast.error(data.message);
      }
    } catch (error) {
      setIsLoading(false);
      setResponseMessage("An error occurred. Please try again.");
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Forgot Password
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                name="email"
                fullWidth
                onChange={handleChange}
                value={email}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleSubmit}>
                {isLoading ? 
                <div className="loader_wrapper">
                  <CircularProgress style={{height:'20px'}}/> 
                </div>
                : "Send Reset Link"}
              </MDButton>
            </MDBox>
            {responseMessage && (
              <MDBox mt={2}>
                <MDTypography variant="body2" color="text">
                  {responseMessage}
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ForgotPassword;

import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { AuthContext } from "context";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from '@mui/material/CircularProgress';


import toast from "react-hot-toast";

function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userCred, setUserCred] = useState({});
  const [errors, setErrors] = useState({});
  const [responseError, setResponseError] = useState("");
  const {
    userData: { role, token },
    setUserData,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  // useEffect(() => {
  //   if (token) {
  //     navigate("/patients");
  //   }
  // }, [token, navigate]);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Update the user credentials
    setUserCred((prevState) => ({
      ...prevState,
      [name]:name==='email' ? value : value,
    }));
  
    // Validate and clear errors in real time for both email and password
    if (name === "email") {
      // Regular expression for custom email validation:
      const emailPattern = /^[a-z][\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
      const invalidPatterns = /(\.\.)|(\.[a-zA-Z]{2,}\.[a-zA-Z]{2,})$/;
  
      if (!value) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Email is required",
        }));
      } else if (!emailPattern.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Invalid Email",
        }));
      } else if (invalidPatterns.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "Email contains invalid patterns",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: "",
        }));
      }
    }
  
    if (name === "password") {
      if (!value) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "Password is required",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          password: "",
        }));
      }
    }
  };

  const validateUserCreds = ({ email, password }) => {
    const errors = {};
  
    // Email validation logic
    const emailPattern = /^[a-z][\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    const invalidPatterns = /(\.\.)|(\.[a-zA-Z]{2,}\.[a-zA-Z]{2,})$/;
  
    if (!email) {
      errors.email = "Email is required";
    } else if (!emailPattern.test(email)) {
      errors.email = "Invalid Email";
    } else if (invalidPatterns.test(email)) {
      errors.email = "Email contains invalid patterns (e.g., double dots or invalid domain)";
    }
  
    // Password validation logic
    if (!password) {
      errors.password = "Password is required";
    }
  
    return errors;
  };

  const handleClick = () => {
    const validationErrors = validateUserCreds(userCred);
    setIsLoading(true);

    if (Object.keys(validationErrors).length === 0) {
      fetch(`https://${process.env.REACT_APP_API_URL}/api/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userCred),
      })
        .then((res) => res.json())
        .then((data) => {
          setIsLoading(false);
          if (data.success) {
            if (data.role === "admin") {
              // Admin bypasses OTP and is directly logged in
              localStorage.setItem("token", data.token);
              localStorage.setItem("role", data.role);
              setUserData({ token: data.token, role: data.role });
              navigate("/patients"); // Redirect to the admin dashboard
            } else {
              // Non-admin users proceed to OTP verification
              localStorage.setItem("tempEmail", userCred.email);
              navigate("/authentication/otp-verification");
            }
          } else {
            setResponseError(data.message);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setResponseError("An error occurred. Please try again.");
        });
    } else {
      setIsLoading(false);
      setErrors(validationErrors);
    }
  };
  // const handleClick = () => {
  //   const validationErrors = validateUserCreds(userCred);
  //   setIsLoading(true);

  //   if (Object.keys(validationErrors).length === 0) {
  //     fetch(`https://${process.env.REACT_APP_API_URL}/api/auth/login`, {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify(userCred),
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         setIsLoading(false);
  //         if (data.role === "admin") {
  //           // Admin bypasses OTP and is directly logged in
  //           localStorage.setItem("token", data.token);
  //           localStorage.setItem("role", data.role);
  //           setUserData({ token: data.token, role: data.role });
  //           navigate("/patients"); // Redirect to the admin dashboard
  //         } else {
  //           // Non-admin users proceed to OTP verification
  //           localStorage.setItem("tempEmail", userCred.email);
  //           navigate("/authentication/otp-verification");
  //         }

  //         if (!data.success) {
  //           setIsLoading(false);
  //           setResponseError(data.message);
  //         }
  //       })
  //   } else {
  //     setIsLoading(false);
  //     setErrors(validationErrors);
  //   }
  // };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign In
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                name="email"
                fullWidth
                onChange={handleChange}
                // error={Boolean(errors.email)}
                helperText={errors.email ? errors.email : ""}
                FormHelperTextProps={{
                  sx: {
                    color: errors.email ? "red" : "inherit",
                  },
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type={showPassword ? "text" : "password"} // Toggle between text and password
                label="Password"
                name="password"
                fullWidth
                onChange={handleChange}
                // error={Boolean(errors.password)}
                helperText={errors.password ? errors.password : ""}
                FormHelperTextProps={{
                  sx: {
                    color: errors.password ? "red" : "inherit",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" style={{ marginRight: 1 }}>
                      <IconButton onClick={togglePasswordVisibility}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}{" "}
                        {/* Toggle icon */}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
            {responseError && (
              <MDTypography variant="body2" color="error" mt={2}>
                {responseError}
              </MDTypography>
            )}
            <MDBox display="flex" alignItems="center" ml={-1}>
              {/* <Switch checked={rememberMe} onChange={handleSetRememberMe} /> */}
              {/* <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography> */}
            </MDBox>
            
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleClick}
              >
                {isLoading ? (
                  <div className="loader_wrapper" style={{
                    height: "20px",
                    width: "40px",
                  }}>
                    <CircularProgress
                      style={{
                        height: "20px",
                        width: "40px",
                      }}
                    />
                  </div>
                ) : (
                  "sign in"
                )}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don't Remember Your Password{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/forgot-password"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Forgot Password
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}
export default Basic;
import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Chart as ChartJS,
  ScatterController,
  PointElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
} from "chart.js";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { jsPDF } from "jspdf";

// Register required Chart.js components
ChartJS.register(
  ScatterController,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

function ScatterPlotChart({ color, title, description, date, chart,ylabel }) {
  const chartRef = useRef(null); // Ref for the canvas element
  const chartInstanceRef = useRef(null); // Ref for the Chart.js instance

  useEffect(() => {
    // Destroy the existing chart instance before creating a new one
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Prepare the data with only NPS and OSS Test on the x-axis
    const scatterData = {
      datasets: chart?.datasets?.map(dataset => ({
        ...dataset,
        data: dataset.data
          .filter(item => item.y !== null)  // Remove null values
          .map(item => ({
            x: item.x === 'NPS' ? 0 : 1,  // Assign numerical values to 'NPS' and 'OSS Test'
            y: item.y,  // Clinical score value
          })),
      })),
    };

    // Create a new scatter plot instance
    const ctx = chartRef.current.getContext("2d");
    chartInstanceRef.current = new ChartJS(ctx, {
      type: "scatter",
      data: scatterData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: "category",
            labels: ["NPS", "OSS Test"],  // Only two values for x-axis
            offset: true,  // Ensures that labels aren't crowded
            grid: {
              display: true,
              color:'#49a3f1'
            },
            ticks: {
              color: "white", // Y-axis labels color
              font: {
                size: 12,
                weight: "bold",
              },
            }
          },
          y: {
            type: "linear",
            min: 0,
            ticks: {
              stepSize: 1,
              beginAtZero: true,  // Make sure y-axis starts from 0
            },
            grid: {
              display: true,
              color:'#49a3f1'
            },
            ticks: {
              color: "white", // Y-axis labels color
              font: {
                size: 12,
                weight: "bold",
              },
            },
            title:{
              display: true,
              text:ylabel || "",
              color:'white'
            }
          },
        },
        plugins: {
          legend: {
            display: true,
            position: "top",
            labels: {
                color: "white", // Legend text color
                font: {
                  size: 12,
                  weight: "bold",
                },
              },
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => `${tooltipItem.dataset.label}: ${tooltipItem.raw.y}`,
            },
          },
        },
      },
    });
  }, [chart]);

  const handleDownloadPDF = () => {
    const canvas = chartRef.current;
    
    // Set higher resolution (e.g., 2x DPI)
    const tempCanvas = document.createElement("canvas");
    const ctx = tempCanvas.getContext("2d");
  
    // Double the canvas size for higher quality
    tempCanvas.width = canvas.width * 2;
    tempCanvas.height = canvas.height * 2;
  
    // Set background to white to improve label visibility
    ctx.fillStyle = "#1A73E8";
    ctx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);
  
    // Scale up the existing chart
    ctx.drawImage(canvas, 0, 0, tempCanvas.width, tempCanvas.height);
  
    const chartImage = tempCanvas.toDataURL("image/png"); // Convert to higher-res image
  
    // Generate PDF
    const pdf = new jsPDF("l", "mm", "a4"); // Landscape mode for better fit
    pdf.setFontSize(18);
    pdf.text(title, 10, 15);
    pdf.addImage(chartImage, "PNG", 10, 20, 280, 150); // Position and scale the image
    pdf.save("chart.pdf");
  };

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox padding="1rem">
        <MDBox
          variant="gradient"
          bgColor={color}
          borderRadius="lg"
          coloredShadow={color}
          py={2}
          pr={0.5}
          mt={-5}
          height="15.5rem"
        >
          <canvas ref={chartRef} />
        </MDBox>
        <MDBox pt={3} pb={1} px={1}>
          <MDTypography variant="h6" textTransform="capitalize">
            {title}
          </MDTypography>
          <MDTypography
            component="div"
            variant="button"
            color="text"
            fontWeight="light"
          >
            {description}
          </MDTypography>
          <Divider />
          <MDBox display="flex" justifyContent="space-between">
          <MDBox display="flex" alignItems="center">
            <MDTypography
              variant="button"
              color="text"
              lineHeight={1}
              sx={{ mt: 0.15, mr: 0.5 }}
            >
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography
              variant="button"
              color="text"
              fontWeight="light"
            >
              {date}
            </MDTypography>
            </MDBox>
            <FileDownloadIcon onClick={handleDownloadPDF} style={{ cursor: "pointer" }}/>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ScatterPlotChart
ScatterPlotChart.defaultProps = {
  color: "info",
  description: "",
};

// Typechecking props for the ScatterPlotChart
ScatterPlotChart.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  ).isRequired,
};

export default ScatterPlotChart;

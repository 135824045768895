import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


const decode = (token) => {
    if (!token || token.split(".").length !== 3) {
      return undefined;
    }
    try {
      const decdodedData = jwtDecode(token);
      if (!decdodedData) {
        throw new Error("Token is not valid");
      }
      return decdodedData;
    } catch (error) {
      console.log(error, "showt the decode error......");
      return null;
    }
  };

const verifyToken = (token) => {
    try {
      if (!token) {
        return false;
      }
      if (decode(token)) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return error;
    }
  };

const PublicRoute = ({ children }) => {
    console.log('comin in public route');
  const isLoggedIn = localStorage.getItem("token");
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (verifyToken(isLoggedIn)) {
      return navigate("/patients");
    } else {
        console.log("comin in");
      setIsCheckingAuth(false);
    }
  }, [navigate]);
  if (isCheckingAuth) {
    return null;
  }
  return <div>{children}</div>;
};
export default PublicRoute;